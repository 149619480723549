import React, { useContext, useMemo } from 'react'
import { observer } from 'mobx-react'
import { Menu, Dropdown } from 'semantic-ui-react'
import { LoginContext } from '../context'
import { TenantInfo } from './tenantInfo'
import { action, computed, makeObservable } from 'mobx'
import { useNavigate } from 'react-router'

export const TenantMenuItem = observer(() => {
  const loginStore = useContext(LoginContext)
  const navigate = useNavigate()
  const store = useMemo(
    () =>
      makeObservable(
        {
          get tenants() {
            return loginStore.user.tenants || []
          },
          get tenantId() {
            return loginStore.tenant.id
          },
          get options() {
            return this.tenants.map((x) => ({
              key: x.id,
              value: x.id,
              text: x.company || x.tenant,
              tenant: x,
            }))
          },
          async handleChange(_, { value }) {
            const t = this.options.filter((x) => x.key === value)
            if (t.length > 0) {
              await loginStore.setTenant(t[0].tenant)
              navigate('/release')
            }
          },
        },
        {
          tenants: computed,
          tenantId: computed,
          options: computed,
          handleChange: action.bound,
        }
      ),
    [loginStore, history]
  )

  switch (store.options.length) {
    case 0:
      return null
    case 1:
      return (
        <Menu.Item className="userMenu">
          <TenantInfo />
        </Menu.Item>
      )
    default:
      return (
        <Dropdown
          item
          options={store.options}
          className="userMenu"
          pointing="top"
          scrolling
          onChange={store.handleChange}
          value={store.tenantId}
          trigger={<TenantInfo />}
        />
      )
  }
})
