import React, { useContext, useEffect } from 'react'
import { Segment, Image } from 'semantic-ui-react'
import { observer, useLocalObservable } from 'mobx-react'
import { LoginContext } from '../context'
import { useDropzone } from 'react-dropzone'
import { toast } from 'react-toastify'
import { base } from '../base'
import { action, runInAction } from 'mobx'

export function coverLink(release, version, size = 300) {
  if (!release.cover) return '/wireframe.png'
  if (version === undefined) {
    version = release.cover.md5
  }

  return `${base}api/release/${release.id}/cover/${size}?v=${version}`
}

export const CoverImage = observer(({ release }) => {
  const link = coverLink(release)
  return <Image rounded size="small" src={link} />
})

export const CoverImageUpload = observer(({ release }) => {
  const loginStore = useContext(LoginContext)
  const store = useLocalObservable(() => ({
    release,

    loading: false,

    maxFiles: 1,

    get src() {
      return coverLink(this.release)
    },

    async onDrop(acceptedFiles) {
      this.loading = true
      const {
        accessToken,
        options: { base },
      } = loginStore
      const toastId = toast('Uploading cover image...')
      const body = new FormData()
      body.append('file', acceptedFiles[0])
      try {
        const response = await fetch(
          `${base}api/release/${this.release.id}/cover`,
          {
            method: 'POST',
            headers: { Authorization: `Bearer ${accessToken}` },
            body,
          }
        )
        if (response.ok) {
          const cover = (await response.json()).cover
          //Object.assign(release.cover, cover)
          runInAction(() => (release.cover = cover))
          toast.update(toastId, {
            render: `Updated cover image ${cover.fileName}`,
          })
        }
      } catch (e) {
        toast.update(toastId, { type: toast.TYPE.ERROR, render: e.toString() })
      } finally {
        this.loading = false
      }
    },
  }))
  useEffect(
    action(() => {
      store.release = release
    }),
    [release]
  )

  const { getRootProps, getInputProps } = useDropzone(store)

  return (
    <Segment loading={store.loading} basic>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <Image rounded size="medium" src={store.src} />
      </div>
      <div className="imageInfo">
        {release.cover && (
          <>
            <span>
              <a
                href={`${base}api/release/${release.id}/file/${release.cover.fileName}`}
                download
              >
                {release.cover.fileName}
              </a>
            </span>
            <span>
              {release.cover.width}x{release.cover.height} px
            </span>
          </>
        )}
        {!release.cover && (
          <span>
            Drop cover image here. Image should be JPEG or PNG, rectangular and
            at least 1000x1000 px.
          </span>
        )}
      </div>
    </Segment>
  )
})
