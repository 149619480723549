import React, { useContext } from 'react'
import LoginPage from './loginPage'
import { observer } from 'mobx-react'
import { LoginContext } from '../context'

export const LoginOverlay = observer(({ children }) => {
  const loginStore = useContext(LoginContext)
  if (!loginStore.initialized) {
    return null
  } else if (loginStore.authenticated) {
    return children
  }
  return <LoginPage />
})

export default LoginOverlay
