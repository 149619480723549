import React from 'react'
import { observer } from 'mobx-react'
import {
  BrowserRouter as Router,
  Navigate,
  NavLink,
  Route,
  Routes,
} from 'react-router-dom'
import { Menu, Segment } from 'semantic-ui-react'
import loadable from '@loadable/component'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { LoginOverlay } from './login/loginOverlay'
import { Scope } from './login/scope'
import { AudioPlayerMenuItem } from './player/audioPlayer'
import { TenantMenuItem } from './tenant/tenantMenuItem'
import { UserMenuItem } from './user/userMenuItem'
import { AccessDeniedModal } from './login/accessDeniedModal'

// Create loadable pages
const LoadingSpinner = <Segment loading style={{ height: '12em' }} />

const Page = ({ component, page }) => {
  const C = loadable(() => import(`./${component}/${page}Page`), {
    fallback: LoadingSpinner,
  })
  return <C />
}

const MenuNavLink = ({ children, color = 'gray', to, ...rest }) => (
  <NavLink className={`${color} item`} to={to} {...rest}>
    {children}
  </NavLink>
)

const Home = () => <Navigate to="/release" />

export const App = observer(() => (
  <Router>
    <LoginOverlay>
      <header>
        <div className="menu-container">
          <Menu as="nav" secondary>
            <Scope scope="release">
              <MenuNavLink color="purple" to="/release">
                Releases
              </MenuNavLink>
              <MenuNavLink color="green" to="/artist">
                Artists
              </MenuNavLink>
              <MenuNavLink color="teal" to="/track">
                Tracks
              </MenuNavLink>
              <MenuNavLink color="orange" to="/top100">
                Top 100
              </MenuNavLink>
              <MenuNavLink color="pink" to="/history">
                History
              </MenuNavLink>
            </Scope>
            <Scope scope="super">
              <MenuNavLink color="teal" to="/user">
                Users
              </MenuNavLink>
            </Scope>
            <Menu.Menu position="right">
              <AudioPlayerMenuItem />
              <TenantMenuItem />
              <UserMenuItem />
            </Menu.Menu>
          </Menu>
        </div>
      </header>
      <main>
        <Scope invert scope="nologin">
          <Routes>
            <Route
              path="/release"
              element={<Page component="release" page="releaseList" />}
            />
            <Route
              path="/release/:id/*"
              element={<Page component="release" page="releaseView" />}
            />
            <Route
              path="/track"
              element={<Page component="track" page="trackList" />}
            />
            <Route
              path="/track/:id/*"
              element={<Page component="track" page="trackView" />}
            />
            <Route
              path="/artist"
              element={<Page component="artist" page="artistList" />}
            />
            <Route
              path="/artist/:id/*"
              element={<Page component="artist" page="artistView" />}
            />
            <Route
              path="/top100"
              element={<Page component="play" page="playList" />}
            />
            <Route
              path="/report"
              element={<Page component="report" page="reportUpload" />}
            />
            <Route
              path="/user"
              element={<Page component="user" page="userList" />}
            />
            <Route
              path="/history"
              element={<Page component="history" page="historyList" />}
            />
            <Route path="/" element={<Home />} />
          </Routes>
        </Scope>
      </main>
      <AccessDeniedModal />
      <ToastContainer position={toast.POSITION.TOP_RIGHT} />
    </LoginOverlay>
  </Router>
))

export default App
