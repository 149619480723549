import React, { useContext } from 'react'
import { Modal, Button } from 'semantic-ui-react'
import { Scope } from './scope'
import { LoginContext } from '../context'

export const AccessDeniedModal = () => {
  const loginStore = useContext(LoginContext)
  return (
    <Scope scope="nologin">
      <Modal open size="small">
        <Modal.Header>Access Denied</Modal.Header>
        <Modal.Content>
          <p>Your account has been disabled.</p>
          <p>Please contact system administrator to restore access.</p>
        </Modal.Content>
        <Modal.Actions>
          <Button primary content="Sign out" onClick={loginStore.logout} />
        </Modal.Actions>
      </Modal>
    </Scope>
  )
}
