import React, { useContext } from 'react'
import { observer } from 'mobx-react'
import { Dropdown } from 'semantic-ui-react'
import { LoginContext } from '../context'
import { UserInfo } from './userInfo'

export const UserMenuItem = observer(() => {
  const loginStore = useContext(LoginContext)
  return (
    <Dropdown className="userMenu" pointing item trigger={<UserInfo />}>
      <Dropdown.Menu>
        <Dropdown.Header>E-mail</Dropdown.Header>
        <Dropdown.Item>{loginStore.user.email}</Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item onClick={loginStore.logout}>Sign out</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
})
