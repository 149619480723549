import React, { useEffect, useContext } from 'react'
import { observer, useLocalObservable } from 'mobx-react'
import { Dropdown } from 'semantic-ui-react'
import { Page } from './page'
import { ApiContext } from '../components/context'

const defaultFilter = {
  limit: 10,
}

export const PostgrestDropdown = observer(
  ({
    name = undefined,
    value = undefined,
    item = undefined,
    multiple = false,
    defaultSearchQuery = undefined,
    'pg-type': Type,
    'pg-select': select,
    pg = undefined,
    renderOptions = undefined,
    clearing = false,
    placeholder = undefined,
    fluid = false,
    onChange,
    onAddItem,
    renderLabel,
    allowAdditions,
  }) => {
    const api = useContext(ApiContext)
    const store = useLocalObservable(() => ({
      search: new Page(Type, api, Object.assign({ select }, defaultFilter, pg)),

      selected: new Page(Type, api, Object.assign({ select }, defaultFilter)),

      setSearchQuery(searchQuery) {
        if (!searchQuery) return
        this.search.query[Type._search || 'q'] = `ilike.*${searchQuery}*`
      },

      loadSelected(value) {
        if (!value) return
        if (!multiple) value = [value]
        //value = value.filter(y => search.content.find(x => x[Type._key] === y))
        if (value.length > 0) {
          this.selected.query[Type._key] = `in.(${value.join(',')})`
          this.selected.load()
        }
      },

      async handleSearchChange(_, { searchQuery }) {
        this.setSearchQuery(searchQuery)
        try {
          await this.search.load()
        } catch (ignore) {
          console.debug('Ignoring', ignore)
        }
      },

      async handleOpen() {
        try {
          await this.search.load()
        } catch (ignore) {
          console.debug('Ignoring', ignore)
        }
      },

      searchFn(x) {
        return x
      },

      get options() {
        const keys = this.selected.content.map((x) => x[Type._key])
        let opts = []
        opts = opts.concat(this.selected.content.slice())
        opts = opts.concat(
          this.search.content.filter((x) => !keys.includes(x[Type._key]))
        )
        opts = opts.map((x) => ({
          key: x[Type._key],
          value: x[Type._key],
          text: x.toString(),
          object: x,
        }))
        if (renderOptions) opts = opts.map(renderOptions)
        if (clearing) {
          opts.unshift({
            key: '',
            value: null,
            icon: 'remove',
            text: clearing,
          })
        }
        return opts
      },
    }))

    useEffect(
      () => store.setSearchQuery(defaultSearchQuery),
      [store, defaultSearchQuery]
    )
    useEffect(() => store.loadSelected(value), [store, value])

    return (
      <Dropdown
        selection
        multiple={multiple}
        fluid={fluid}
        search={store.searchFn}
        defaultSearchQuery={defaultSearchQuery}
        lazyLoad
        loading={store.search.loading}
        options={store.options}
        onSearchChange={store.handleSearchChange}
        onChange={onChange}
        onOpen={store.handleOpen}
        value={value}
        name={name}
        item={item}
        placeholder={placeholder}
        renderLabel={renderLabel}
        allowAdditions={allowAdditions}
        onAddItem={onAddItem}
      />
    )
  }
)

/*
export const FilterPostgrestDropdown = observer(({
  filter,
  label,
  name,
  ...rest
}) => (
    // @ts-ignore
    <PostgrestDropdown
      {...rest}
      value={filter[name]}
      name={name}
      onChange={action((e, { name, value }) => filter[name] = value)}
    />
  ))
  */
