import { computed, isObservableArray, observable, toJS } from 'mobx'
import { makeObservableDef } from '../components/common/makeObservableDef'

const isDtoProperty = (k) => k === 'snapshot' || k === 'dry' || k === 'dirty'

export const DtoClass = ({
  base = class {},
  key = 'id',
  search = 'q',
  path,
  hydrate = {},
  dehydrate = {},
  decorate,
}) =>
  class extends base {
    static _path = path
    static _key = key
    static _search = search

    constructor(data) {
      super()
      makeObservableDef(this, {
        dry: computed,
        dirty: computed,
        snapshot: observable,
        ...decorate,
      })
      this.snapshot = {}
      if (data) this.dry = data
    }

    get dry() {
      const no = this[key] === undefined
      const r = {} // { dirty: this.dirty }
      for (const k in this) {
        const value = this[k]
        if (isDtoProperty(k) || value === undefined) {
          // Skip
        } else if (k === key) {
          r[k] = value
        } else if (isObservableArray(value)) {
          if (
            no ||
            value.find((x) => x.dirty === true) ||
            (this.snapshot[k] || []).length !== value.length
          ) {
            const h = dehydrate[k]
            const v = value.map((x) => (x.hasOwnProperty('dry') ? x.dry : x))
            r[k] = h ? h(v) : v
          }
        } else if (no || this.snapshot[k] !== value || dehydrate[k] === always) {
          if (value.hasOwnProperty('dry')) {
            if (no || value.dirty) {
              r[k] = value.dry
            }
          } else {
            const h = dehydrate[k]
            r[k] = h ? h(value) : value
          }
        }
      }
      return r
    }

    set dry(data) {
      for (const k in data) {
        if (data.hasOwnProperty(k) && !isDtoProperty(k)) {
          const v = data[k]
          if (v !== undefined) {
            const h = hydrate[k]
            const x = h ? h(v, this[k]) : v
            this[k] = x
            const d = dehydrate[k]
            this.snapshot[k] = toJS(d ? d(x) : x)
          }
        }
      }
    }

    get dirty() {
      for (const k in this) {
        const value = this[k]
        if (isDtoProperty(k) || value === undefined) {
          // Skip
        } else if (isObservableArray(value) && dehydrate[k] !== ignore) {
          const a = value.find((x) => x.dirty === true)
          if (a) return true
          const b = this.snapshot[k] || []
          if (b.length !== value.length) return true
        } else if (value && value.dirty === false) {
          // Skip
        } else {
          const h = dehydrate[k]
          const v = h ? h(value) : value
          if (this.snapshot[k] !== v) {
            return true
          }
        }
      }

      return this[key] === undefined
    }
  }

export const ignore = (x) => undefined

export const always = (x) => x

export const dto = (Constructor) => (x) => new Constructor(x)

export const dtoArray = (Constructor) => {
  if (!Constructor) console.warn('expected constructor')
  return (x, p) => {
    if (p === undefined) {
      return observable.array(x.map((e) => new Constructor(e)))
    } else {
      p.replace(x.map((e) => new Constructor(e)))
      return p
    }
  }
}

export const objectId = (o) => {
  if (!o) return null
  const symbol = Object.getOwnPropertySymbols(o).find(
    (x) => x.toString() === 'Symbol(mobx administration)'
  )
  return symbol ? o[symbol].name : o.toString()
}
