import React from 'react'
import { Menu, Image } from 'semantic-ui-react'
import { observer } from 'mobx-react'
import { action, observable } from 'mobx'
import { Howl } from 'howler'
import { toast } from 'react-toastify'
import { base } from '../base'
import { performer } from '../contributor/contributors'
import { coverLink } from '../cover/coverImage'

export const AudioPlayer = observable(
  {
    howl: null,
    howlId: null,
    playing: false,
    track: null,

    play(track) {
      if (track && !track.dispatchConfig) {
        this.stop()
        this.howl = new Howl({
          src: [track.src],
          html5: true,
          onpause: this.onpause,
          onplay: this.onplay,
          onend: this.onend,
          onstop: this.onend,
        })
        this.track = track
        toast(
          <>
            <Image rounded floated="left" size="mini" src={track.cover} />
            <div>
              <b>{track.performer}</b>
            </div>
            <div>{track.title}</div>
          </>
        )
      }
      if (this.howl) this.howlId = this.howl.play()
    },

    stop() {
      if (this.howl) {
        this.howl.unload()
        /*
      this.playing = false
      this.howl = null
      this.howlId = null
      this.track = null
      */
      }
    },

    pause() {
      if (this.howl) this.howl.pause()
    },

    onplay(id) {
      //console.log('play', this.howlId, id)
      if (this.howlId === id) this.playing = true
    },

    onpause(id) {
      //console.log('pause', id)
      if (this.howlId === id) this.playing = false
    },

    onend(id) {
      //console.log('end', id)
      if (this.howlId === id) {
        this.track = null
        this.howl = null
        this.howlId = null
        this.playing = false
      }
    },
  },
  {
    howl: observable.ref,
    track: observable.ref,
    play: action.bound,
    stop: action.bound,
    pause: action.bound,
    onplay: action.bound,
    onpause: action.bound,
    onend: action.bound,
  }
)

export const AudioPlayerMenuItem = observer(({ player = AudioPlayer }) => {
  const { track, pause, play, playing } = player
  if (!track) return null

  return (
    <Menu.Item
      onClick={playing ? pause : play}
      icon={playing ? 'pause' : 'play'}
      content={
        <>
          <b>{track.performer || 'Unknown'}</b>: {track.title}
        </>
      }
    />
  )
})

export const play = (release, track) => () => {
  //console.log('P', release, track)
  AudioPlayer.play({
    src: `${base}api/release/${release.id}/file/${track.audioFiles[0].fileName}`,
    cover: coverLink(release),
    performer: performer(track)
      .map((x) => x.name)
      .join(', '),
    title: track.title,
  })
}
