import React, { useContext } from 'react'
import { observer } from 'mobx-react'
import { Image } from 'semantic-ui-react'
import { LoginContext } from '../context'
import { base } from '../base'

export const UserInfo = observer(() => {
  const loginStore = useContext(LoginContext)
  return (
    <>
      <Image avatar src={`${base}api/user/${loginStore.user.id}/avatar`} />
      &nbsp;
      {loginStore.user.name}
    </>
  )
})
