// @ts-nocheck
import { observable } from 'mobx'
import { DtoClass, dto, dtoArray, ignore, always } from '../store/dto'

export const WebPage = DtoClass({
  base: class {
    toString() {
      return this.link
    }
  },
  decorate: {
    platform: observable,
    link: observable,
  },
})

export const Artist = DtoClass({
  base: class {
    toString() {
      return this.name
    }
  },
  path: 'artist',
  decorate: {
    name: observable,
  },
  hydrate: {
    profiles: dtoArray(WebPage),
  },
})

export const Contributor = DtoClass({
  base: class {
    toString() {
      return this.artist.toString()
    }
  },
  hydrate: {
    artist: dto(Artist),
  },
  decorate: {
    artist: observable,
    role: observable,
  },
})

export const AudioFile = DtoClass({
  path: 'audio',
  key: 'fileName',
  decorate: {
    bitsPerSample: observable,
    samplingRate: observable,
  },
  dehydrate: {
    fileName: ignore,
    codec: always,
    size: always,
  }
})


export const Track = DtoClass({
  base: class {
    toString() {
      return this.isrc
    }
  },
  path: 'track',
  hydrate: {
    contributors: dtoArray(Contributor),
    audioFiles: dtoArray(AudioFile)
  },
  dehydrate: {
    product: ignore,
    relatedTracks: ignore,
    videoFile: ignore,
  },
  decorate: {
    isrc: observable,
    title: observable,
    genre: observable,
    discNumber: observable,
    trackNumber: observable,
    physicalReleaseDate: observable,
    digitalReleaseDate: observable,
    copyright: observable,
    producerLine: observable,
    contributors: observable,
  },
})

export const Distribution = DtoClass({
  path: 'distribution',
})

export const Release = DtoClass({
  base: class {
    toString() {
      return this.upc
    }
  },
  path: 'release',
  hydrate: {
    tracks: dtoArray(Track),
    contributors: dtoArray(Contributor),
    distributions: dtoArray(Distribution),
  },
  dehydrate: {
    distributions: ignore,
    cover: ignore,
  },
  decorate: {
    upc: observable,
    title: observable,
    genre: observable,
    label: observable,
    physicalReleaseDate: observable,
    digitalReleaseDate: observable,
    copyright: observable,
    producerLine: observable,
    cover: observable,
    contributors: observable,
    distributions: observable,
  },
})

export const Play = DtoClass({
  path: 'play',
})

export const History = DtoClass({
  path: 'history',
})

export const User = DtoClass({
  path: 'user',
})
