import { action, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import React, { useCallback, useContext, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Icon } from 'semantic-ui-react'
import { ApiContext } from '../context'
import { PostgrestDropdown } from '../../store/postgrestDropdown'
import { Artist, Contributor } from '../model'

export const Contributors = ({ value, role = 'performer', max = 100 }) => {
  const list = (value.contributors || []).filter(
    (x) => x.role === role && x.artist
  )

  return (
    <>
      <div className="contributors">
        {list.slice(0, max).map((c, i, a) => (
          <Link
            key={i}
            to={`/artist/${c.artist.id}/tracks`}
            className="contributor"
          >
            {c.artist.name}
          </Link>
        ))}
      </div>
      {list.length > max && '...'}
    </>
  )
}

const contr = (value, role = 'performer') =>
  (value.contributors || [])
    .filter((x) => x.role === role && x.artist)
    .map((x) => x.artist)

export const performer = (value) => contr(value, 'performer')

export const InlineContributorEditor = observer(
  ({ value, role = 'performer' }) => {
    const store = useMemo(
      () =>
        observable.object(
          {
            value,
            editing: false,
            toggle() {
              this.editing = !this.editing
            },
          },
          {
            toggle: action.bound,
          }
        ),
      [value]
    )

    if (store.editing) {
      return <ContributorsDropdown value={store.value} role={role} />
    } else {
      return (
        <div className={`ineditor`}>
          <Contributors value={store.value} role={role} />
          <Icon tabIndex={0} name="pencil" onClick={store.toggle} />
        </div>
      )
    }
  }
)

export const ContributorsDropdown = observer(
  ({ value, role = 'performer' }) => {
    const api = useContext(ApiContext)

    const addItem = useCallback(
      async (e, x) => {
        const result = await api.post('artist', { name: x.value })
        if (result.ok) {
          const artist = await result.json()
          runInAction(() =>
            value.contributors.push(new Contributor({ role, artist }))
          )
        }
      },
      [value, role, api]
    )

    const change = useCallback(
      action((_, { value: v, options }) => {
        const a = v
          .map((id) => options.find((x) => id === x.key))
          .filter((x) => x)
          .map(
            (x) =>
              new Contributor({ artist: { id: x.key, name: x.text }, role })
          )
          .concat(value.contributors.filter((x) => x.role !== role))
        value.contributors.replace(a)
      }),
      [value, role]
    )

    const items = value.contributors
      .filter((x) => x.role === role)
      .map((x) => x.artist.id)
      .slice()

    return (
      // @ts-ignore
      <PostgrestDropdown
        pg-type={Artist}
        multiple
        placeholder={`${role}...`}
        pg={pg}
        value={items}
        onChange={change}
        onAddItem={addItem}
        allowAdditions
        fluid
      />
    )
  }
)

const pg = { limit: 100, order: 'name' }
