import { observable, action, runInAction } from 'mobx'
import qs from 'query-string'
import { makeObservableDef } from '../common/makeObservableDef'

const defaultOptions = {
  refreshPath: 'api/access_token',
  storage: localStorage,
}

export class LoginStore {
  constructor(options) {
    makeObservableDef(this, {
      initialized: observable,
      loading: observable,
      user: observable.ref,
      accessToken: observable,
      onResponse: action,
      setLoading: action,
      setAccessToken: action.bound,
      setUser: action,
      setTenant: action.bound,
      refresh: action.bound,
      logout: action.bound,
      tenant: observable.ref,
    })
    this.initialized = false
    this.options = Object.assign({}, defaultOptions, options)
  }

  setLoading(loading) {
    this.loading = loading
  }

  setUser(user) {
    this.user = user
    if (user) {
      const t = user.tenants || []
      if (t.length > 0) {
        const z = +this.options.storage.tenant
        const k = t.findIndex((x) => x.id === z)
        this.tenant = t[k !== -1 ? k : 0]
      }
    }
  }

  bindTenant = (t) => this.setTenant(t)

  async setTenant(t) {
    await this.refresh(this.options.storage.refreshToken, null, t.id)
    runInAction(() => {
      this.tenant = t
      this.options.storage.tenant = t.id
    })
  }

  async onResponse(response) {
    if (response.status !== 200) {
      this.logout()
      throw Error('authentication failed')
    }
    const tokens = await response.json()
    runInAction(() => {
      this.options.storage.refreshToken = tokens.refresh_token
      this.setUser(tokens.me)
      this.setAccessToken(tokens.access_token)
    })
  }

  setAccessToken(token) {
    this.initialized = true
    this.loading = false
    this.accessToken = token
  }

  get authenticated() {
    return Boolean(this.user)
  }

  logout() {
    delete this.options.storage.refreshToken
    this.setAccessToken(null)
    this.setUser(null)
  }

  async refresh(
    token = this.options.storage.refreshToken,
    hash = window.location.hash,
    tenantId = this.options.storage.tenant
  ) {
    const { access_token } = qs.parse(hash)
    if (access_token) {
      //this.setAccessToken(access_token)
      window.location.hash = ''
    }
    if (!token) {
      // console.debug('Refreshing with access token')
      token = access_token
    }
    if (token) {
      let response
      try {
        const o = this.options
        response = await fetch(o.base + o.refreshPath, {
          method: 'POST',
          body: new URLSearchParams(
            `grant_type=refresh_token&refresh_token=${token}${
              tenantId ? '&use_tenant=' + tenantId : ''
            }`
          ),
        })
      } catch (ex) {
        runInAction(() => (this.initialized = true))
        throw ex
      }
      await this.onResponse(response)
    } else {
      this.initialized = true
    }
  }
}
