import React from 'react'
import { Button, Modal } from 'semantic-ui-react'
import { base } from '../base'

const LoginPage = () => (
  <Modal open size="tiny">
    <Modal.Header>Sign in</Modal.Header>
    <Modal.Content>
      <p>
        To sign in you'll need either{' '}
        <a href="https://www.google.com">google</a>
        {' or '}
        <a href="https://facebook.com">facebook</a> account.
      </p>
      <Button
        as="a"
        size="large"
        color="google plus"
        icon="google plus"
        href={`${base}api/login/google`}
        content="Google"
      />
      <Button
        as="a"
        disabled
        size="large"
        color="facebook"
        icon="facebook"
        href={`${base}api/login/facebook`}
        content="Facebook"
      />
      <p style={{ marginTop: '1rem' }}>
        {
          'Click on the link above to continue. When prompted, please allow access to '
        }
        <b>lala.io</b> to your's account.
      </p>
    </Modal.Content>
  </Modal>
)

export default LoginPage
