export const RETURN_REPRESENTATION = {
  headers: { Prefer: 'return=representation' },
}
export const MERGE_DUPLICATES = {
  headers: { Prefer: 'resolution=merge-duplicates' },
}

const defaultOptions = {
  base: '/api',
  credentials: 'include',
  headers: {},
}

const merge = ({ headers: ha = {}, ...a }, { headers: hb, ...b } = {}) =>
  Object.assign({
    headers: Object.assign(ha, hb),
    ...a,
    ...b,
  })

export class PostgrestClient {
  constructor(options) {
    this.options = options
  }

  fetch(path, options) {
    const { base, ...rest } = merge(
      merge(defaultOptions, this.options),
      options
    )
    return fetch(base + path, rest)
  }

  delete(path, options) {
    return this.fetch(path, { method: 'DELETE', ...options })
  }

  post(path, body, options) {
    return this.fetch(
      path,
      merge(
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(body),
        },
        options
      )
    )
  }

  patch(path, body, options) {
    return this.post(path, body, { method: 'PATCH', ...options })
  }
}
