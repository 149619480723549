import React, { useContext } from 'react'
import { observer } from 'mobx-react'
import { Image } from 'semantic-ui-react'

import { LoginContext } from '../context'

export const TenantInfo = observer(() => {
  const loginStore = useContext(LoginContext)
  const t = loginStore.tenant
  if (t.logo) {
    return <Image className="companyLogo" src={`${t.logo}`} />
  } else {
    return <>{t.company || t.tenant}</>
  }
})
