import { useContext } from 'react'
import { observer } from 'mobx-react'
import { LoginContext } from '../context'

export const Scope = observer(({ scope, invert = false, children }) => {
  const loginStore = useContext(LoginContext)
  const user = loginStore.user
  const inScope = user && user.scope && user.scope.includes(scope)
  if (!(inScope ^ (invert ? 1 : 0))) return null
  return children
})
