import React from 'react'
import { LoginStore } from './login/loginStore'
import { reaction } from 'mobx'
import { base } from './base'
import { PostgrestClient } from '../store/postgrestClient'

const loginStore = new LoginStore({ base })
setTimeout(loginStore.refresh, 0)
setInterval(loginStore.refresh, 20 * 60 * 1000)

const headers = {}
reaction(
  () => loginStore.accessToken,
  (token) => {
    if (token) headers.Authorization = 'Bearer ' + token
  }
)

const postgrest = new PostgrestClient({ base: base + 'api/', headers })

export const ApiContext = React.createContext(postgrest)

export const LoginContext = React.createContext(loginStore)
