import React from 'react'
import {
  action,
  computed,
  toJS,
  observable,
  set,
  extendObservable,
  makeObservable,
} from 'mobx'
import qs from 'query-string'
import { toast } from 'react-toastify'
import { SimpleCache } from './simpleCache'

export const single = (x) => (Array.isArray(x) && x ? x[0] : x)

export const multi = (x) => (Array.isArray(x) ? x : x !== undefined ? [x] : x)

const justFilter = (q) => {
  const { limit, select, order, offset, ...rest } = q
  return rest
}

const typeToConstructor = (type) => {
  if (typeof type === 'string') {
    return class {
      constructor(data) {
        Object.assign(this, data)
      }
      static _path = type
      static _key = type + '_id'
    }
  }
  return type
}
export const PageContext = React.createContext(null)

export class Page {
  total = null
  _loading = 0
  _initial = true
  content = observable.array()
  cache = new SimpleCache()

  constructor(type, client, filter) {
    makeObservable(this, {
      total: observable,
      _loading: observable,
      _initial: observable,
      pages: computed,
      page: computed,
      sort: computed,
      search: computed,
      updateSearch: computed,
      filtered: computed,
      clearFilter: action.bound,
      markFilter: action.bound,
      loadingLong: computed,
      ascending: computed,
      size: computed,
      single: computed,
      load: action.bound,
      insert: action,
      update: action,
      delete: action,
      initalLoading: computed,
    })

    this.type = typeToConstructor(type)
    this.client = client
    this.savedQuery = extendObservable({}, filter)
    this.query = extendObservable({}, filter)
  }

  get pages() {
    const { limit } = this.query
    return this.total !== null && limit > 0
      ? Math.ceil(this.total / limit)
      : null
  }

  get page() {
    const { offset, limit } = this.query
    return limit > 0 ? Math.ceil(offset / limit) : null
  }

  get sort() {
    const o = this.query.order
    return o ? o.split('.')[0] : undefined
  }

  get search() {
    return qs.stringify(toJS(this.query))
  }

  get updateSearch() {
    return qs.stringify(justFilter(this.query))
  }

  get filtered() {
    const ar = justFilter(this.savedQuery)
    const br = justFilter(this.query)
    const a = qs.stringify(ar)
    const b = qs.stringify(br)
    //console.log('Fa',ar, 'Fb', br, a !== b)
    return a !== b
  }

  clearFilter() {
    set(this.query, this.savedQuery)
  }

  markFilter() {
    set(this.savedQuery, this.query)
    return toJS(this.query)
  }

  get initalLoading() {
    return this.single == null || (this.initialLoad && this.loading)
  }

  get loading() {
    return this._loading > 0
  }

  get loadingFor() {
    return this._loading > 0 ? Date.now() - this._loading : 0
  }

  get loadingLong() {
    return this.loadingFor > 2000
  }

  get initialLoad() {
    return this._initial
  }

  set sort(by) {
    if (by !== this.query.order) {
      this.query.order = by
      this.query.offset = 0
    }
  }

  get ascending() {
    const o = this.query.order
    if (!o) return true
    const x = o.split('.')
    return x.length === 0 || x[1] !== 'desc'
  }

  set ascending(asc) {
    const s = this.sort
    if (s) this.sort = s + (!asc ? '.desc' : '')
  }

  set page(value) {
    const tp = this.pages
    if (value === 0 || (value > 0 && (tp == null || value < tp))) {
      if (value !== this.page) {
        this.query.offset = Math.floor(value * this.query.limit)
      }
    }
  }

  get last() {
    const { limit } = this.query
    return limit > 0 && this.total !== null
      ? Math.ceil(this.total / limit)
      : null
  }

  get size() {
    return this.query.limit
  }

  get single() {
    return this.content.length > 0 ? this.content[0] : null
  }

  set single(x) {
    this.content.replace([x])
  }

  _start() {
    const now = Date.now()
    this._loading = now
    return now
  }

  _checkResponse = (started) => (response) => {
    if (response.status < 200 || response.status >= 300) {
      return response.json().then((reason) => {
        console.error('Postgrest API failed', reason)
        toast.error(
          <>
            <div>Pogreška</div>
            <div>{reason.message}</div>
          </>
        )
        return Promise.reject(reason)
      })
    }
    return response
  }

  _end(started) {
    if (started === this._loading) this._loading = 0
    this._initial = false
  }

  reload() {
    this.cache.clear()
    return this.load()
  }

  load() {
    const search = this.search
    const started = this._start()

    const setContent = action(({ content, range }) => {
      this.total = range.total
      this.query.offset = range.offset
      this.content.replace(content.map((x) => new this.type(x)))
      this._end(started)
    })

    if (this.cache) {
      let result = this.cache.get(search)
      if (result) {
        setContent(result)
        if (!result.expired) return Promise.resolve()
      }
    }

    const options = { headers: {} }
    if (this.query.limit !== null) {
      options.headers['Range'] = 'items'
      options.headers['Prefer'] = 'count=exact'
    }

    return this.client
      .fetch(`${this.type._path}?${search}`, options)
      .then((response) => {
        if (response.status === 416 && this.page > 0) {
          //console.log('Retry on first page')
          this.page = 0
          return this.client.fetch(`${this.type._path}?${this.search}`, options)
        }
        return response
      })
      .then(this._checkResponse(started))
      .then((response) => {
        const range = {}
        const contentRange = response.headers.get('Content-Range')
        if (contentRange) {
          // eslint-disable-next-line
          const [first, last, count] = contentRange.split(/-|\//)
          range.total = count === '*' ? null : parseInt(count, 10) || 0
          range.offset = parseInt(first, 10) || 0
          //range.last = parseInt(last, 10) || 0
        }
        return Promise.all([response.json(), range])
      })
      .then(([content, range]) => {
        if (this.search !== search) return Promise.reject('Canceled')
        const result = { content, range }
        if (this.cache) this.cache.set(search, result)
        setContent(result)
      })
  }

  // return=representation / return=minimal / resolution=ignore-duplicates / resolution=merge-duplicates
  insert(content, prefer) {
    const started = this._start()
    return this.client
      .fetch(this.type._path, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Prefer: prefer,
        },
        body: JSON.stringify(content),
      })
      .then(this._checkResponse(started))
      .then(
        action((response) => {
          this._end(started)
          if (prefer === 'return=representation') {
            let r = response.json()
            if (content.length === undefined) r = r.then((x) => x[0])
            return r
          } else {
            const location = response.headers.get('Location')
            if (location) {
              const key = this.type._key
              if (key) {
                const rx = new RegExp(`([a-z]+)\\?${key}=eq\\.(\\d+)`)
                const idMatch = location.match(rx)
                if (idMatch) {
                  const id = +idMatch[2]
                  if (content.length !== undefined) {
                    if (content.length === 1) content[key] = id
                  } else {
                    content[key] = id
                  }
                }
              }
            }
            return content
          }
        })
      )
  }

  update(content) {
    const started = this._start()
    return this.client
      .fetch(`${this.type._path}?${this.updateSearch}`, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(content),
      })
      .then(this._checkResponse(started))
      .then((x) => x.json())
      .then(
        action((response) => {
          this._end(started)
          return response
        })
      )
  }

  delete() {
    const started = this._start()
    return this.client
      .fetch(`${this.type._path}?${this.updateSearch}`, {
        method: 'DELETE',
      })
      .then(this._checkResponse(started))
      .then(
        action(() => {
          this._end(started)
        })
      )
  }

  bindSetPageSize = (size) =>
    action(() => {
      localStorage['limit.' + this.type._path] = size
      const q = this.query
      q.limit = size
      q.offset = 0
    })
}

export const createPageFromProps = (props) => {
  const { 'pg-type': type, 'pg-load': load, postgrest } = props
  const filter = pageQueryProps(props)

  const p = new Page(type, postgrest, filter)
  if (load) p.load()
  return p
}

const pageQueryProps = (props) => {
  let {
    'pg-type': type,
    'pg-value': value,
    'pg-ref': ref,
    'pg-key': key,
    'pg-load': load,
    postgrest,
    page,
    children,
    select = '*',
    offset = 0,
    limit,
    order,
    ...rest
  } = props
  Object.keys(rest).forEach((k) => {
    let v = rest[k]
    delete rest[k]
    k = k.replace('-', '.')
    rest[k] = v
  })

  if (!limit && type) {
    const k = type._path || type
    limit = +(localStorage['limit.' + k] || 10)
  }

  return { select, offset, limit, order, ...rest }
}
